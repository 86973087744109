/*
  Mixin for creating pseudo elements
*/
/*
  Mixins to support bootstrap spacing
 */
.password-req-checker,
.password-req-checker.show-invalid-fields .valid {
  display: none;
}

.password-req-checker.active,
.password-req-checker.show-invalid-fields {
  display: block;
}

.password-requirements {
  font-size: 0.75rem;
}
.password-requirements .valid {
  color: #88c290;
}

@media (min-width: 768px) {
  .account-auth_col:first-of-type {
    border-right: 1px solid #e6e6e6;
  }
}
.account-auth_content {
  max-width: 24rem;
  width: 100%;
}