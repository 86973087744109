.password-req-checker,
.password-req-checker.show-invalid-fields .valid {
    display: none;
}

.password-req-checker.active,
.password-req-checker.show-invalid-fields {
    display: block;
}

.password-requirements {
    @include font-size(12px);

    .valid {
        color: $success;
    }
}

;@import "sass-embedded-legacy-load-done:1210";